import React from 'react';
import linkedin from '../assets/socials/linkedin-ico.png'
import github from '../assets/socials/github-ico.png'
import {HashLink} from "react-router-hash-link";

function HeroSocial({link, img}) {
    return (
        <div className="home-hero__social">
            <a href={link} className="home-hero__social-icon-link">
                <img
                    src={img}
                    alt="icon"
                    className="home-hero__social-icon"
                />
            </a>
        </div>
    )
}

function Hero({title, subtitle, heros}) {
 return (
     <section className="home-hero" id="home">
         <div className="home-hero__content">
             <h1 className="heading-primary">{title}</h1>
             <div className="home-hero__info">
                 <p className="text-primary">
                     {subtitle}
                 </p>
             </div>
             <div className="home-hero__cta">
                 <HashLink to={"/#projects"} className="btn btn--bg">Projects</HashLink>
                 {/*<a href="./#projects" className="btn btn--bg">Projects</a>*/}
             </div>
         </div>
         <div className="home-hero__socials">

             {heros}

         </div>
         <div class="home-hero__mouse-scroll-cont">
             <div class="mouse"></div>
         </div>
     </section>)
}

export {Hero, HeroSocial};
