import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

function About({tags, ...props}) {
    return (
        <section id="about" className="about sec-pad">
            <div className="main-container">
                <h2 className="heading heading-sec heading-sec__mb-med">
                    <span className="heading-sec__main">About Me</span>
                    {/*<span className="heading-sec__sub">*/}
                    {/*    {subtitle}*/}
                    {/*</span>*/}
                </h2>
                <div className="about__content">

                    <div className="about__content-main">
                        <h3 className="about__content-title">Get to know me!</h3>
                        <div className="about__content-details">
                            {props.children}
                        </div>

                        <a href={"/Resume-maxime.madrau.pdf"} className="btn btn--med btn--theme dynamicBgClr"> Resume </a>
                        <HashLink to={"/#contact"} className="btn btn--med btn--theme dynamicBgClr"> Contact </HashLink>
                    </div>

                    <div className="about__content-skills">
                        <h3 className="about__content-title">Computer Skills</h3>
                        <div className="skills">
                            {tags.map((tag, index) => {
                                return (
                                    <div key={index} className="skills__skill">
                                        {tag}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;
