import ProjectHeader from "./Components/ProjectHeader";
import ProjectDetail from "./Components/ProjectDetail";

import mt from "../../assets/projects/mastertrip.png"

function ProjectMasterTrip() {
    return (
        <>
            <ProjectHeader title="Master-Trip" website={"https://mastertrip.app/"}>
                Master-Trip is an iOS and watchOS app I develop and sell on the App Store. It's an odometer that allows
                users to measure a distance and record their trips.
            </ProjectHeader>


            <ProjectDetail img={mt}
                           skills={[
                               "Swift",
                               "Objective-C",
                               "iOS",
                               "SwiftUI",
                               "Xcode",
                               "HTML",
                               "CSS",
                               "JavaScript",
                               "Apache",
                               "Git",
                               "WatchOS"
                           ]}>

                <h3 className="project-details__content-title">Project Overview</h3>
                <p className="project-details__desc-para">
                    Master-Trip is the most precise, complete and customisable trip master on iOS.
                    It includes features like:

                    <ul className="project-details__desc-ul">
                        <li>Total and partial distance counters</li>
                        <li>Total timer</li>
                        <li>Quick adjust buttons</li>
                        <li>Pause</li>
                        <li>Forward or reverse mode</li>
                        <li>Auto-lock disabling</li>
                    </ul>
                </p>
                <p className="project-details__desc-para">
                    You can set up to 6 handy counters, that will display useful informations such as Speed, Altitude,
                    Clock, Heading (true and magnetic), Longitude & Latitude, Current city, GPS Signal status and your
                    Battery status
                    And if you don’t like the default counters fonts, you can choose among 6 different fonts.
                </p>
                <p className="project-details__desc-para">
                    You can record your full tracks in order to allow you to share your trips with your friends, or
                    watch them later on a computer via the build-in web server.
                </p>

                <p className="project-details__desc-para">
                    Going for a night trip? You can switch interface colours between a light theme, a night theme and an
                    OLED theme that will save your battery life.
                </p>
                <p className="project-details__desc-para">
                    With Flic buttons, you can control Master-Trip by the press of a physical button. You can set up to
                    7 actions for 3 different triggers.
                </p>

                <p className="project-details__desc-para">
                    If you have an Apple Watch, the Watch companion app will allow you to check all counters and to
                    pause/resume directly on your wrist.
                </p>
            </ProjectDetail>

        </>
    )
}

export default ProjectMasterTrip;
