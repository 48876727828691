import logo from './logo.svg';
import {Outlet, BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import  { Navigate } from 'react-router-dom'
// import './App.css';
import './sass/main.scss';
import HomePage from "./Pages/HomePage/HomePage";
import ProjectVITK from "./Pages/Projects/VITK";
import Header from "./Components/Header";
import React from "react";
import {Footer, FooterSocial} from "./Components/Footer";
import github from "./assets/socials/github-ico.png";
import linkedin from "./assets/socials/linkedin-ico.png";
import ProjectPTG from "./Pages/Projects/TextureGenerator";
import ProjectMasterTrip from "./Pages/Projects/MasterTrip";
import ProjectGPGPU from "./Pages/Projects/GPGPU";

function App() {
    return (
        <div className="app">
            <Router>
                {/* Header */}
                <Header links={[
                    {name: "Home", link: "/#home"},
                    {name: "About", link: "/#about"},
                    {name: "Projects", link: "/#projects"},
                    {name: "Contact", link: "/#contact"}
                ]}/>



                <Routes>
                    <Route exact path="/" element={<HomePage/>}/>
                    <Route exact path="/projects/vitk" element={<ProjectVITK/>}/>
                    <Route exact path="/projects/ptg" element={<ProjectPTG/>}/>
                    <Route exact path="/projects/mastertrip" element={<ProjectMasterTrip/>}/>
                    <Route exact path="/projects/gpgpu" element={<ProjectGPGPU/>}/>
                </Routes>




                {/* Footer */}
                <Footer socials={
                    <>
                        <FooterSocial icon={github} link={"https://github.com/Maxmad68"}/>
                        <FooterSocial icon={linkedin} link={"https://www.linkedin.com/in/maxime-madrau/"}/>
                    </>
                }>
                    Computer Engineering student with a passion for imaging, specializing in medical imaging and iOS development
                </Footer>

            </Router>
        </div>
    );
}

export default App;
