import ProjectHeader from "./Components/ProjectHeader";
import ProjectDetail from "./Components/ProjectDetail";

import gpgpu from "../../assets/projects/gpgpu.png";
import inside from "../../assets/projects/isim_inside.png"

function ProjectGPGPU() {
    return (
        <>
            <ProjectHeader title="Moving object segmentation on GPU">
                The project focuses on optimizing the separation of background and moving objects in videos through GPU
                processing.
            </ProjectHeader>

            <ProjectDetail img={gpgpu}
                           skills={[
                               "C++",
                               "CUDA",
                               "GPU",
                               "Image Processing",
                           ]}>

                <h3 className="project-details__content-title">Project Overview</h3>
                <p className="project-details__desc-para">
                    The GPGPU Video Segmentation project harnesses the power of GPU computing to optimize the process of
                    distinguishing moving objects from static backgrounds in video streams. This high-performance
                    solution is ideal for real-time applications across various domains such as surveillance, event
                    streaming, and automated video editing. The project employs NVIDIA's CUDA for GPU acceleration,
                    enabling parallel processing that dramatically enhances processing speed and efficiency compared to
                    traditional CPU-based approaches.
                </p>

                <p className="project-details__desc-para">Developed initially in C++, the project's algorithms leverage
                    both the CUDA C/C++ extensions to fully utilize the parallel architecture of modern GPUs. This
                    strategic use of technologies ensures that the system can handle large volumes of video data quickly
                    and accurately. The integration of custom benchmarking tools, also developed using C++, allows for
                    precise measurement of the system's performance improvements, including processing speeds and the
                    accuracy of video segmentation.</p>
                <h3 className="project-details__content-title">Outcome</h3>
                <p className="project-details__desc-para">
                    The successful implementation of the project resulted in a robust video segmentation system that
                    significantly speeds up the processing of video data while maintaining high accuracy in object and
                    background separation. This project illustrates the transformative impact of advanced GPU
                    programming and positions it as a key technology for scaling and enhancing video processing
                    applications in practical, real-world settings.
                </p>
            </ProjectDetail>

        </>
    )
}

export default ProjectGPGPU;
