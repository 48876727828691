import ProjectHeader from "./Components/ProjectHeader";
import ProjectDetail from "./Components/ProjectDetail";

import vitk from "../../assets/projects/vitk.png";

function ProjectVITK() {

    return (
        <>
            <ProjectHeader title="Longitudinal Tumor Evolution Study with ITK/VTK">
                This project employed ITK and VTK libraries to perform automated longitudinal tumor tracking, including
                image registration, tumor segmentation, and visualizing changes over time, offering valuable insights
                for medical research and treatment planning.
            </ProjectHeader>

            <ProjectDetail img={vitk}
                           skills={[
                               "Python",
                               "Jupyter",
                               "VTK",
                               "ITK",
                               "Medical Imaging",
                           ]}>

                <h3 className="project-details__content-title">Project Overview</h3>
                <p className="project-details__desc-para">
                    This project involved the longitudinal study of tumor evolution using the ITK (Insight
                    Segmentation and Registration Toolkit) and VTK (Visualization Toolkit) libraries. The
                    primary
                    aim was to track and analyze changes within a tumor by comparing two medical scans from the
                    same
                    patient, acquired at different time points.
                </p>
                <h3 className="project-details__content-title">Key Objectives</h3>

                <p className="project-details__desc-para">
                    Image Registration: Automated alignment of two medical images.
                    Tumor Segmentation: Identification and delineation of tumors in both images.
                    Change Analysis: Calculation and visualization of differences between the two tumors.
                </p>
                <h3 className="project-details__content-title">Approach</h3>

                <p className="project-details__desc-para">
                    We used a variety of techniques, including ITK's registration algorithms for image alignment
                    and
                    tumor segmentation. The project emphasized automation to ensure reproducibility and ease of
                    use.
                </p>
                <h3 className="project-details__content-title">Achievements</h3>

                <p className="project-details__desc-para">
                    Successfully registered and compared medical images from the same patient.
                    Developed automated tumor segmentation methods for both image sets.
                    Calculated and visualized changes in tumor size and intensity.
                </p>
                <h3 className="project-details__content-title">Results</h3>
                <p className="project-details__desc-para">
                    The project produced valuable insights into tumor evolution over time, potentially aiding
                    medical professionals in treatment planning and assessment.
                </p>
            </ProjectDetail>

        </>
    )

}

export default ProjectVITK;
