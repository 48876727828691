import React from 'react';
import Header from "../../Components/Header";
import {Hero, HeroSocial} from "../../Components/Hero";
import About from "../../Components/About";
import { Project, Projects } from "../../Components/Projects";
import {FooterSocial, Footer} from "../../Components/Footer";

//import script from '../../script.js'

import Contact from "../../Components/Contact";

import mastertrip from "../../assets/projects/mastertrip.png";
import isim from "../../assets/projects/mockup-isim.jpeg";
import vitk from "../../assets/projects/vitk.png";
import gpgpu from "../../assets/projects/gpgpu.png";
import linkedin from "../../assets/socials/linkedin-ico.png";
import github from "../../assets/socials/github-ico.png";

function HomePage() {
    return (
        <>


            {/* Hero section */}
            <Hero
                title="Hey, My name is Maxime"
                subtitle="Computer Engineer with a passion for image sciences, iOS and web development"
                heros={
                    <>
                        <HeroSocial link={"https://www.linkedin.com/in/maxime-madrau/"} img={linkedin}/>
                        <HeroSocial link={"https://github.com/Maxmad68"} img={github}/>
                    </>
                }
            />


            {/* About section */}
            <About
                tags={["Python", "Swift", "C#", "C", "C++", "Java", "OCaml", "Rust", "JavaScript", "SQL", "HTML", "CSS", "Lua", "Git", "iOS", "UNIX/Linux", "Image Synthesis", "Image Processing", "Ray-Tracing", "Machine-Learning", "Medical Imaging"]}
            >

                <p>
                    I'm Maxime, a Computer Engineering student shortly graduate at EPITA and Sorbonne University, specializing in <b>medical imaging</b> and <b>image processing</b>. In addition to my studies, I'm passionate about <b>iOS development</b>. My portfolio showcases my journey as a student and developer, where I explore the fusion of technology and healthcare to make a positive impact through innovative solutions. Thanks for visiting!
                    Feel free to <strong>contact</strong> me here.
                </p>
            </About>


            {/* Projects section */}
            <Projects>
                <Project title="Master-Trip"
                         subtitle="Since 2019"
                         image={mastertrip}
                         element={"/projects/mastertrip"}>
                    Master-Trip is an iOS and watchOS app I develop and sell on the App Store. It's an odometer that allows users to measure a distance and record their trips.
                </Project>

                <Project title="Moving object segmentation on GPU"
                            subtitle="2023"
                            image={gpgpu}
                            element={"/projects/gpgpu"}>
                    The project focuses on optimizing the separation of background and moving objects in videos through GPU processing. The goal is to achieve real-time processing and improve the accuracy of object detection.
                </Project>

                <Project title="Procedural Texture Generator"
                         subtitle="2023"
                         image={isim}
                         element={"/projects/ptg"}>
                    A C++ project featuring a user-friendly interface, parallelization for optimal performance, and the ability to create a diverse range of natural textures.
                </Project>


                <Project title="Longitudinal Tumor Evolution Study with ITK/VTK"
                            subtitle="2023"
                            image={vitk}
                            element={"/projects/vitk"}>
                    The project aims to perform a longitudinal study of tumor evolution by using ITK and VTK libraries to register two scans taken at different times from the same patient, segment tumors, and visualize the changes between the two tumors, with a final goal of producing an automated result.
                </Project>

            </Projects>


            {/* Contact section */}
            <Contact/>

        </>

    );
}

export default HomePage;
