import avatar from "../assets/max.png";
import {HashLink} from "react-router-hash-link";

import ham from "../assets/svg/ham-menu.svg";
import ham_close from "../assets/svg/ham-menu-close.svg";
import {createRef} from "react";
import {Link} from "react-router-dom";

function Header({links}) {
    let menu = createRef();
    let hamMenuBtn = createRef();
    let hamMenuCloseBtn = createRef();

    var menuActive = false

    let openMenu = () => {
        menu.current.classList.add('header__sm-menu--active');
        hamMenuBtn.current.classList.add('d-none');
        hamMenuCloseBtn.current.classList.remove('d-none');
        menuActive = true;
    }

    let closeMenu = () => {
        menu.current.classList.remove('header__sm-menu--active');
        hamMenuBtn.current.classList.remove('d-none');
        hamMenuCloseBtn.current.classList.add('d-none');
        menuActive = false;
    }

    let clickMenuButton = () => {
        if (menuActive) {
            closeMenu()
        } else {
            openMenu()
        }
    }

    return (
        <header className="header">
            <div className="header__content">
                <Link to={"/"}>
                    <div className="header__logo-container">
                        <div className="header__logo-img-cont">
                            <img
                                src={avatar}
                                alt="Maxime Madrau"
                                className="header__logo-img"
                            />
                        </div>
                        <span className="header__logo-sub">Maxime Madrau</span>
                    </div>
                </Link>
                <div className="header__main">
                    <ul className="header__links">
                        {links.map((link, index) => {
                            return (
                                <li key={index} className="header__link-wrapper">
                                    <HashLink to={link.link} className="header__link"> {link.name} </HashLink>
                                </li>
                            )})
                        };

                    </ul>
                    <div className="header__main-ham-menu-cont" onClick={clickMenuButton}>
                            <img
                                src={ham}
                                alt="hamburger menu"
                                className="header__main-ham-menu"
                                ref={hamMenuBtn}
                            />
                            <img
                                src={ham_close}
                                alt="hamburger menu close"
                                className="header__main-ham-menu-close d-none"
                                ref={hamMenuCloseBtn}
                            />
                    </div>
                </div>
            </div>
            <div className={"header__sm-menu"} ref={menu}>
                <div className="header__sm-menu-content">
                    <ul className="header__sm-menu-links">

                        {links.map((link, index) => {
                            return (
                                <li key={index} className="header__sm-menu-link" onClick={closeMenu}>
                                    <HashLink to={link.link}> {link.name} </HashLink>
                                </li>
                            )})
                        }
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header;
