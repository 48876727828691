function ProjectDetail({img, skills, ...props}) {
    return (
        <section className="project-details">
            <div className="main-container">
                <div className="project-details__content">
                    {img && <div className="project-details__showcase-img-cont">
                        <img
                            src={img}
                            className="project-details__showcase-img"
                            alt="project showcase"
                        />
                     </div>}

                    <div className="project-details__content-main">
                        <div className="project-details__desc">
                            {props.children}

                        </div>
                        <div className="project-details__tools-used">
                            <h3 className="project-details__content-title">Skills</h3>
                            <div className="skills">
                                {skills.map((skill, index) => {
                                    return (
                                        <div key={index} className="skills__skill">{skill}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectDetail;
