function ProjectHeader({title, website, ...props}) {
    return (
        <section className="project-cs-hero">
            <div className="project-cs-hero__content">
                <h1 className="heading-primary">{title}</h1>
                <div className="project-cs-hero__info">
                    <p className="text-primary">
                        {props.children}
                    </p>
                </div>
                {website && <div className="project-cs-hero__cta">
                    <a href={website} className="btn btn--bg" target="_blank">Website</a>
                </div>}
            </div>
        </section>
    )
}

export default ProjectHeader;
