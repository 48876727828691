import ProjectHeader from "./Components/ProjectHeader";
import ProjectDetail from "./Components/ProjectDetail";

import ptg from "../../assets/projects/isim.png";
import inside from "../../assets/projects/isim_inside.png"

function ProjectPTG() {
    return (
        <>
            <ProjectHeader title="Procedural Texture Generator">
                A C++ project featuring a user-friendly interface, parallelization for optimal performance, and the
                ability to create a diverse range of natural textures.

            </ProjectHeader>

            <ProjectDetail img={ptg}
                           skills={[
                               "C++",
                               "GTK",
                               "Ray-Tracing",
                               "Image Synthesis",
                               "Git",
                           ]}>

                <h3 className="project-details__content-title">Project Overview</h3>
                <p className="project-details__desc-para">
                    This school project is a Procedural Texture Generator developed in C++. It is designed to replicate
                    natural textures with precision and creativity. The project features an intuitive Graphical User
                    Interface (GUI) created using GTK, enabling users to apply various filters for texture
                    customization. Notably, the project optimizes performance through parallelization techniques.
                    Additionally, it integrates seamlessly with a raytracer, expanding its utility to 3D rendering on
                    different surfaces and materials.
                </p>
                <h3 className="project-details__content-title">Key Features</h3>
                <p className="project-details__desc-para">
                    <p><b>Diverse Texture Generation</b>: The Procedural Texture Generator can produce a wide range of natural
                    textures, including wood, grass, sky, lava, fire, cotton, and more. This versatility caters to the
                    needs of artists and 3D designers.</p>
                    <p><b>Layered Filter Approach</b>: Complex textures are created by stacking filters. The project optimizes
                    performance by parallelizing these filter operations, reducing rendering times.</p>
                    <p><b>Random Noise Initialization</b>: Random noise is used in initial operations to introduce natural
                    variations into the generated textures. This randomness serves as a foundation for subsequent
                    filtering and transformations.</p>
                    <p><b>Coloration in Final Operations</b>: Advanced coloration techniques are applied in the final stages to
                    enhance textures, infusing them with vibrant and realistic colors.</p>
                    <p><b>User-Friendly Interface</b>: The project offers a user-friendly GTK-based UI, simplifying the process of
                    adding and adjusting filters, making it accessible to users of various technical backgrounds.
                    Integration with Raytracer: Beyond texture generation, the project can seamlessly integrate with a
                    raytracer. This allows users to apply the generated textures to different volumes and materials,
                        expanding its utility in 3D rendering.</p>
                </p>
                <h3 className="project-details__content-title">Implications</h3>
                <p className="project-details__desc-para">
                    This project demonstrates expertise in C++ programming and proficiency in parallelization, random
                    noise generation, and coloration techniques. It is a valuable tool for computer graphics, game
                    development, and 3D modeling, emphasizing both technical and artistic abilities.
                </p>

                <img src={inside} alt={""}/>
            </ProjectDetail>

        </>
    )
}

export default ProjectPTG;
