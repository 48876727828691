function FooterSocial({ icon, link }) {
    return (
        <a target="_blank" rel="noreferrer" href={link}>
            <img className="main-footer__icon" src={icon} alt="icon" />
        </a>
    );
}

function Footer({socials, ...props}) {
    return (
        <footer className="main-footer">
            <div className="main-container">
                <div className="main-footer__upper">

                    <div className="main-footer__row main-footer__row-1">
                        <h2 className="heading heading-sm main-footer__heading-sm">
                            <span>Social</span>
                        </h2>
                        <div className="main-footer__social-cont">

                            {socials}

                        </div>
                    </div>

                    <div className="main-footer__row main-footer__row-2">
                        <h4 class="heading heading-sm text-lt">Maxime MADRAU</h4>
                        <p class="main-footer__short-desc">
                            {props.children}
                        </p>
                    </div>
                </div>

                <div class="main-footer__lower">
                    &copy; Copyright 2021. Made by
                    <a rel="noreferrer" target="_blank" href="https://rammaheshwari.com">Ram Maheshwari</a>
                </div>
            </div>
        </footer>
    )
}

export {FooterSocial, Footer };
