import {Link} from "react-router-dom";

function Project({title, subtitle, image, element, ...props}) {
    return (
        <div className="projects__row">
            <div className="projects__row-img-cont">
                <div className="projects__row-img-cont">
                    <img
                        src={image}
                        alt={title}
                        className="projects__row-img"
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="projects__row-content">
                <h3 className="projects__row-content-title">{title}</h3>
                <h2 className="projects__row-content-subtitle">{subtitle}</h2>
                <p className="projects__row-content-desc">
                    {props.children}
                </p>
                <Link
                    className="btn btn--med btn--theme dynamicBgClr"
                    target="_blank"
                    to={element}>
                    Case Study
                </Link>
            </div>
        </div>
    )
}

function Projects(props) {
    return (
        <section id="projects" className="projects sec-pad">
            <div className="main-container">
                <h2 className="heading heading-sec heading-sec__mb-bg">
                    <span className="heading-sec__main">Projects</span>
                    <span className="heading-sec__sub">
                        Here are some of the projects I work(ed) on :
                    </span>
                </h2>

                <div className="projects__content">


                    {props.children}


                </div>

                {/*<div style={{textAlign: "center"}}>*/}
                {/*    <Link to={"/projects"} className="btn btn--med btn--theme dynamicBgClr"> See More </Link>*/}
                {/*</div>*/}
            </div>
        </section>
    )
}

export {Projects, Project}
